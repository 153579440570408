export default {
    props: ["drawer-name"],
    methods: {
        close() {
            this.parent().closeDrawer(this.drawerName);
        },
        parent() {
            return this.$parent.$parent;
        },
        opened() {
            this.afterOpened();
        },
        closed() {
            this.afterClosed();
        }
    },
}